import React from "react"
import { Cloudinary } from "@cloudinary/url-gen"
import { AdvancedImage } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as contactDirectStyle from "./contact-direct.module.css"

const ContactDirect = ({ pagedata }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  return (
    <>
      <h2 className="mb-4">Direct Email</h2>

      {pagedata.cfContact.contactDirDirectContacts.map((contact, index) => {
        let imgUri = contact.contactDirPhoto.mediaDetails.file
        let theImage = cld.image(
          `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
        )

        theImage.quality("70")
        theImage.resize(fill().width(140).height(140))
        theImage.format("auto")

        return (
          <div key={index} className="d-flex mb-5">
            <AdvancedImage
              cldImg={theImage}
              alt={contact.contactDirName}
              className={`${contactDirectStyle.contact_profile_pic} me-4`}
            />
            <span>
              <h3 className="h4 mb-0">{contact.contactDirAreaOfContact}</h3>
              <p className="mb-0">{contact.contactDirName}</p>
              <a
                href={`mailto:${contact.contactDirEmailAddress}`}
                title={`email ${contact.contactDirEmailAddress}`}
                className="ani-underline"
              >
                {contact.contactDirEmailAddress}
              </a>
            </span>
          </div>
        )
      })}
    </>
  )
}

export default ContactDirect
