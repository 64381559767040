import React from "react"
import { graphql } from "gatsby"
//import { Helmet } from "react-helmet"
import { ParallaxProvider } from "react-scroll-parallax"
import Layout from "./Layout"
import HeroSimpleLeft from "../components/shared/hero-simple-left"
import ContactForm from "../components/contact-us-page/contact-form"
import ContactDirect from "../components/contact-us-page/contact-direct"
import OurStudios from "../components/contact-us-page/our-studios"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfHeroBlockSimpleLeft {
          heroblocksimpleleftIntroText
          heroblocksimpleleftTitle
        }

        cfContact {
          contactWarningText
          contactDirDirectContacts {
            contactDirAreaOfContact
            contactDirEmailAddress
            contactDirName
            contactDirPhoto {
              altText
              mediaDetails {
                file
              }
            }
          }
        }
      }
    }
  }
`
const PageTemplateContact = ({ data }) => {
  const page = data.wpgraphql.page

  return (
    <>
      <Layout>
        <HeroSimpleLeft pagedata={page.cfHeroBlockSimpleLeft} />

        <div className="container mt-5">
          <div className="row justify-content-between">
            <main className="col-12 col-xl-6">
              <ContactForm pagedata={page} />
            </main>

            <aside className="col-12 col-xl-5 mt-5 mt-xl-0">
              <ContactDirect pagedata={page} />
            </aside>
          </div>
        </div>

        <ParallaxProvider>
          <OurStudios pagedata={page} />
        </ParallaxProvider>
      </Layout>
    </>
  )
}

export default PageTemplateContact

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
